<template>
  <div>
    <div class="vasion-page-title">
      Cloud Storage
    </div>
    <h5>Available Providers</h5>
    <div class="provider-container">
      <md-card class="vasion-icon-card">
        <img src="@/assets/images/googleDrive.png" alt="googleDrive" @click="signIn('googleDrive')">
      </md-card>
      <md-card class="vasion-icon-card">
        <img src="@/assets/images/oneDrive.png" alt="oneDrive" @click="signIn('oneDrive')">
      </md-card>
      <md-card class="vasion-icon-card">
        <img src="@/assets/images/dropBox.png" alt="dropBox" @click="signIn('dropBox')">
      </md-card>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CloudStorageAdmin',
  components: {
  },
  data: function () {
    return {
      item: '',
    }
  },
  computed: {
    // selectedFolder() { return this.$store.state.common.selectedFolder },
  },
  created: function () {
  },
  methods: {
    dropBoxSignIn() {
      console.log('dropBoxSignIn') // TODO: Needs Update
    },
    googleDriveSignIn() {
      this.$gAuth.signIn()
      .then((GoogleUser) => {
        // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
        console.log('user', GoogleUser.getAuthResponse())
        // GoogleUser.getId() : Get the user's unique ID string.
        // GoogleUser.getBasicProfile() : Get the user's basic profile information.
        // GoogleUser.getAuthResponse() : Get the response object from the user's auth session. access_token and so on
        this.isSignIn = this.$gAuth.isAuthorized
      })
      .catch((error) => {
        // on fail do something
        console.warn(error)
      })
    },
    oneDriveSignIn() {
      console.log('oneDriveSignIn') // TODO: Needs Update
    },
    signIn(value) {
      switch (value) {
        case 'googleDrive':
          this.googleDriveSignIn()
          break;
        case 'oneDrive':
          this.oneDriveSignIn()
          break;
        case 'dropBox':
          this.dropBoxSignIn()
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/variables.scss';
  .provider-container {
    display: flex;
  }
  .icon-card {
    margin-right: 10px;
  }
</style>
